<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="columns panel" style="padding: 1em 1em 0.5em">
        <div class="column">
          <b-field label="ຈາກວັນທີ" horizontal>
            <b-datepicker
              v-model="fromdate"
              icon="calendar-today"
              @input="changeFromDate"
              :locale="this.getDateLocale"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="column">
          <b-field label="ຫາວັນທີ" horizontal>
            <b-datepicker
              v-model="todate"
              icon="calendar-today"
              @input="changeTodate"
              :locale="this.getDateLocale"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-2">
          <b-button class="is-info" @click="get()" icon-left="magnify">
          </b-button>
        </div>
      </div>
      <b-table
        :loading="isLoading"
        :paginated="perPage < models.length"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        default-sort="name"
        :data="models"
        :current-page.sync="currentPage"
        :pagination-rounded="true"
      >
        <b-table-column label="#" v-slot="props">
          {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
        </b-table-column>
        <b-table-column label="ເລກທີໃບນຳສົ່ງ" sortable v-slot="props">
          {{ props.row.code }}
        </b-table-column>
        <b-table-column label="ເວລາ" sortable v-slot="props">
          {{ props.row.created_time | datetime }}
        </b-table-column>
        <b-table-column label="ປ້າຍລົດ" sortable v-slot="props">
          {{ props.row.vehicle.license_no }}
        </b-table-column>
        <b-table-column label="ສະຖານະ" sortable v-slot="props">
          <div v-if="props.row.status == 'waiting'" class="tag is-warning">
            ລໍຖ້າສາຂາປາຍທາງຮັບ
          </div>
          <div v-if="props.row.status == 'received'" class="tag is-success">
            ສາຂາປາຍທາງຮັບແລ້ວ
          </div>
        </b-table-column>
        <b-table-column
          custom-key="actions"
          cell-class="is-actions-cell"
          v-slot="props"
        >
          <div class="buttons is-right">
            <b-button
              class="is-small is-info"
              @click="view(props.row)"
              icon-left="eye"
            >
            </b-button>
            <!-- <b-button
                class="is-small is-danger"
                @click="remove(props.row)"
                icon-left="trash-can"
              >
              </b-button> -->
          </div>
        </b-table-column>

        <empty-table v-if="models.length == 0" :isLoading="isLoading" />
      </b-table>
    </section>
  </div>
</template>

<script>
import Axios from "axios";
import EmptyTable from "../components/EmptyTable.vue";
import moment from "moment";
export default {
  name: "ShipmentScript",
  components: { EmptyTable },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ໃບນໍາສົ່ງ",
      isLoading: false,
      model: null,
      models: [],
      fromdate: null,
      todate: null,
    };
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  mounted() {
    this.fromdate = new Date();
    this.todate = new Date();
    this.get();
  },
  methods: {
    get() {
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      Axios.get(`shipmentscript/gets/${fdate}/${tdate}`).then((r) => {
        this.models = r;
      });
    },
    view(item) {
      this.model = item;
      this.$router.push({ path: "/ShipmentScriptView/" + this.model.code });
    },
    remove(item) {
      this.$buefy.dialog.confirm({
        title: "ຢືນຢັນການລຶບຂ້ໍມູນ",
        message: "ທ່ານຕ້ອງການ <b>ລຶບ</b> ແທ້ບໍ?",
        confirmText: "ແມ່ນ, ລຶບ",
        cancelText: "ບໍ່",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          Axios.delete(`shipmentscript/${item.id}`).then((r) => {
            console.log(r);
            this.get();
          });
        },
      });
    },
    save() {
      let req;
      if (this.model.id) {
        req = Axios.put("shipmentscript", this.model);
      } else {
        req = Axios.post("shipmentscript", this.model);
      }
      req.then((res) => {
        console.log(res);
        this.closeModal();
        this.get();
      });
    },
    closeModal() {
      this.isShowModal = false;
    },
    changeFromDate(e) {
      this.fromdate = e;
    },
    changeTodate(e) {
      this.todate = e;
    },
  },
};
</script>
